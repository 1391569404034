import {Button, Typography} from '@mui/material';
import {useRouter} from 'next/router';
import {FormattedMessage, useIntl} from 'react-intl';
import {Div} from '../styledComponents/Div';
import {Link} from '../styledComponents/Link';

type Props = {
    readonly statusCode: number;
};

/**
 * Showing content based on incoming error.
 */
export const ErrorPage = ({statusCode}: Props) => {
    const {push} = useRouter();

    const {formatMessage} = useIntl();
    /**
     * Přesměruje uživatele na hlavní stránku (root)
     */
    const handleRootRedirect = () => push('/');

    /**
     * Vrátí nadpis chyby podle http status kódu.
     *
     * @param {number} code - http status code
     */
    const getTitleByStatusCode = (code: number) => {
        switch (code) {
            case 200:
            case 404:
                return <FormattedMessage id="error.http.notFoundTitle" />;
            case 500:
                return <FormattedMessage id="error.http.internalServerErrorTitle" />;
            default:
                return <FormattedMessage id="error.http.unexpectedTitle" />;
        }
    };

    /**
     * Vrátí informační obsah chyby pro uživatele podle http status kódu.
     *
     * @param {number} code
     */
    const getContentByStatusCode = (code: number) => {
        switch (code) {
            // handling pro případ, kdy by uživatel přešel přímo na routu /_error, tak se mu vrátí 200
            case 200:
            // not found
            case 404:
                return (
                    <Typography>
                        <FormattedMessage id="error.http.hint" />
                    </Typography>
                );
            // internal server error
            case 500:
                return (
                    <>
                        <Typography>
                            <FormattedMessage id="error.http.internalServerErrorContent1" />
                        </Typography>
                        <Typography>
                            <FormattedMessage id="error.http.hint" />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id="error.http.internalServerErrorContent2"
                                values={{
                                    kontakt: <Link href={`mailto:${formatMessage({id: 'support.email'})}`}>{formatMessage({id: 'support.email'})}</Link>,
                                }}
                            />
                        </Typography>
                    </>
                );
            // vše ostatní
            default:
                return (
                    <>
                        <Typography>
                            <FormattedMessage id="error.http.unexpectedContent1" />
                        </Typography>
                        <Typography>
                            <FormattedMessage id="error.http.unexpectedContent2" />
                        </Typography>
                        <Typography>
                            <FormattedMessage id="error.http.hint" />
                        </Typography>
                    </>
                );
        }
    };

    return (
        <Div
            sx={{
                textAlign: 'center',
                marginTop: 20,
            }}
        >
            <Typography variant="h2">{getTitleByStatusCode(statusCode)}</Typography>
            <Div
                sx={{
                    padding: 5,
                }}
            >
                {getContentByStatusCode(statusCode)}
            </Div>
            <Button color="primary" variant="contained" disableRipple onClick={handleRootRedirect}>
                <FormattedMessage id="button.root" />
            </Button>
        </Div>
    );
};
