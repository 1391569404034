import {NextPageContext} from 'next';
import {ErrorProps} from 'next/error';
import {ErrorPage} from '../client/common/components/error/ErrorPage';

/**
 * Vlastní chybová stránka, která se zobrazuje při neočekávané chybě, 404 (not found) nebo 500 (internal server error).
 * Chybová stránka je zobrazena pouze v produkčním módu.
 * Pro případ, kdy by uživatel přešel přímo na routu /_error, je to bráno jako chyba 404 (not found).
 * Routa /_error je dostupná přímo i v dev režimu.
 */
const Error = ({statusCode}: ErrorProps) => <ErrorPage statusCode={statusCode} />;

Error.getInitialProps = ({res, err}: NextPageContext) => {
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
    return {statusCode};
};

export default Error;
