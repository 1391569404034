import NextLink from 'next/link';
import {FC, ReactNode} from 'react';
import {Span} from './Span';

type THref = string | {pathname: string; query?: any};

type Props = {
    /**
     * Whether to prefetch
     */
    readonly prefetch?: boolean;
    /**
     * Href as for next Link component
     */
    readonly href: THref;
    /**
     * Variables (string) to append after path, if any
     */
    readonly asVariables?: string;
    readonly children: ReactNode;
};

const buildAs = (href: THref, asVariables?: string) => {
    if (typeof href !== 'object') {
        // No 'as' needed
        return undefined;
    } else {
        return asVariables ? href.pathname + (asVariables ? '/' + asVariables : '') : href.pathname;
    }
};

export const Link: FC<Props> = ({prefetch, href, asVariables, children}) => {
    return (
        <NextLink prefetch={prefetch} href={href} as={buildAs(href, asVariables)} passHref>
            <Span
                sx={{
                    color: 'primary.main',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                }}
            >
                {children}
            </Span>
        </NextLink>
    );
};
